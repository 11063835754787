<template>
  <div>
    <Modal size="sm" ref="modal__export">
      <template #title>Exportar</template>
      <template #content>
        <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
          <form @submit.prevent="handleSubmit(onAction)">
            <div class="export__modal">
              <span>
                <!-- <custom-select
                  v-model="exportConfig.format"
                  :options="Object.values(formats)"
                  label="Formato"
                  rules="required"
                /> -->
                <p>Selecciona el tipo de archivo que deseas exportar el contenido</p>
                <div class="chart__buttons--radio">
                  <ValidationProvider
                    v-for="format in Object.values(formats).reverse()"
                    :key="format.id"
                    tag="label"
                    rules="required"
                    class="radio__label"
                    :class="`radio__label-${format.id}`"
                  >
                    <input
                      type="radio"
                      name="radio"
                      :value="format.id"
                      v-model="exportConfig.format"
                    />
                    <span class="checkmark">
                      <font-awesome-icon :icon="icons[format.id]" size="lg" />
                      <p>{{ format.name }}</p>
                    </span>
                  </ValidationProvider>
                </div>
                <custom-select
                  v-if="formats[exportConfig.format]"
                  v-model="exportConfig.content"
                  :options="formats[exportConfig.format].options"
                  label="Contenido"
                  rules="required"
                />
              </span>
            </div>

            <div class="modal__buttons">
              <Button @click="close" size="medium" variant="text">Cancelar</Button>
              <Button type="submit" :disabled="invalid" size="medium" variant="primary">
                Exportar
              </Button>
            </div>
          </form>
        </validation-observer>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Button from '@/components/buttons/Button.vue';
import CustomSelect from '@/components/custom/CustomSelect.vue';
import Modal from '@/components/Modal.vue';
import { faFileExcel, faFilePowerpoint } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'ExportModal',
  components: {
    Button,
    Modal,
    CustomSelect,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isLoading: false,
      exportConfig: { format: '', content: '' },
      icons: {
        excel: faFileExcel,
        ppt: faFilePowerpoint,
      },
    };
  },

  props: {
    formats: {
      type: Object,
    },
  },

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('categories', ['fetchCategories', 'addCategory']),

    onAction() {
      this.$emit('export', this.exportConfig);
    },

    open() {
      this.$refs.modal__export.open();
    },

    close() {
      this.$refs.modal__export.close();
    },
  },

  computed: {
    ...mapState({
      employees: (state) => state.employees.employees,
      categories: (state) => state.categories.categories,
    }),
  },
};
</script>

<style lang="scss" scoped>
.export__modal {
  padding: 1rem 2rem;
  & > span {
    display: flex;
    flex-flow: column;
    width: 100%;
    gap: 1rem;
  }
  p {
    color: var(--font-color-600);
    line-height: 1rem;
  }
}

.chart__buttons--radio {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.radio__label {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 5.5rem;
  height: 4.5rem;
  color: var(--font-color-200);
}

.radio__label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  border: solid 2px var(--gray-color-200);
  border-radius: 3px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  p {
    font-size: 0.7rem;
    line-height: 0.7rem;
    font-weight: var(--light);
  }
}

/* On mouse-over, add a grey background color */
.radio__label-excel:hover input:not(:checked) ~ .checkmark {
  background-color: var(--gray-color-100);
}
.radio__label-ppt:hover input:not(:checked) ~ .checkmark {
  background-color: var(--gray-color-100);
}

/* When the radio button is checked, add a blue background */
.radio__label-excel input:checked ~ .checkmark {
  border-color: #007139;
  * {
    color: #007139;
    font-weight: vaR(--regular);
  }
}
.radio__label-ppt input:checked ~ .checkmark {
  border-color: #F14521;
  * {
    color: #F14521;
    font-weight: vaR(--regular);
  }
}
</style>
