<template>
  <div class="view__container">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[{ label: 'Correos', to: '/gestion-de-documentos/correos' }]"
          :currentView="{ label: 'Direcciones de correo' }"
        />
        <h2>Direcciones de correo</h2>
      </div>
      <div class="content__buttons">
        <Button type="button" variant="secondary" size="small" @click="openExportModal">
          <unicon width="16px" height="16px" name="file-upload"></unicon>
          Exportar
        </Button>
      </div>
    </div>

    <div class="content">
      <table>
        <tbody>
          <tr>
            <th>#</th>
            <th>Email</th>
            <th v-for="event in events" :key="event">
              {{ event.charAt(0).toUpperCase() + event.slice(1) }}
            </th>
            <th></th>
          </tr>
          <template v-if="addresses.length && !isLoading">
            <tr v-for="(email, index) in addresses" :key="email.id">
              <td>{{ index + 1 }}</td>
              <td>{{ email.id }}</td>
              <td v-for="event in events" :key="event">{{ email[event] || 0 }}</td>
              <td class="redirect">
                <router-link
                  :to="{
                    name: 'Events',
                    params: { id: $route.params.id, emailId: email.id },
                  }"
                  type="button"
                >
                  Ver más...
                </router-link>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-if="isLoading" class="loading"><loading-spinner /></div>
    </div>

    <export-modal
      ref="modal__export"
      @export="downloadExcel"
      :formats="{
        excel: {
          id: 'excel',
          name: 'Excel',
          options: [
            { id: 'mails', name: 'Direcciones de correo' },
          ],
        },
      }"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import Button from '@/components/buttons/Button.vue';
import ExportModal from '@/components/ExportModal.vue';
import * as XLSX from 'xlsx';

export default {
  name: 'Addresses',
  components: {
    Breadcrumbs,
    LoadingSpinner,
    Button,
    ExportModal,
  },
  data() {
    return {
      isLoading: false,
      events: ['processed', 'delivered', 'open', 'bounce', 'dropped', 'spamreport', 'deferred'],
    };
  },

  props: {},

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('emails', ['fetchEmails']),

    openExportModal() {
      this.$refs.modal__export.open();
    },

    downloadExcel() {
      const mails = this.addresses.map((mail) => {
        const mapedMail = {};
        mapedMail.Email = mail.id;
        this.events.forEach((event) => {
          mapedMail[event] = mail[event] || 0;
        });
        return mapedMail;
      });
      console.log(mails);
      const worksheet = XLSX.utils.json_to_sheet(mails);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Correos');
      XLSX.writeFile(workbook, 'reporte.xlsx');
    },
  },

  computed: {
    ...mapState({
      company: (state) => state.company,
      emails(state) {
        const emails = [];
        const eventsName = this.events.reduce((acc, curr) => {
          acc[curr] = 0;
          return acc;
        }, {});
        state.emails.emails.forEach((emailEl) => {
          const email = { ...emailEl, ...eventsName };
          const addresses = [];
          email.addresses.forEach((addressEl) => {
            const address = { ...addressEl, ...eventsName };
            const events = [];
            address.events.forEach((eventEl) => {
              const event = { ...eventEl };
              email[eventEl.event] += 1;
              address[eventEl.event] += 1;
              events.push(event);
            });
            address.events = events;
            addresses.push(address);
          });
          email.addresses = addresses;
          emails.push(email);
        });
        return emails;
      },
    }),

    addresses() {
      if (!this.emails.find(({ id }) => id === this.$route.params.id)) return [];
      return this.emails.find(({ id }) => id === this.$route.params.id).addresses;
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      if (!this.emails.length) this.fetchEmails();
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.view__container {
  border-radius: 10px;
  background-color: white;
  overflow: hidden;

  .content {
    overflow: auto;
  }

  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }

  p {
    font-weight: var(--medium);
    color: var(--font-color-500);
    margin-bottom: 0.5rem;
  }

  label {
    cursor: pointer;
    /* Style as you please, it will become the visible UI component. */
  }

  table {
    width: 100%;
  }
}
.redirect a {
  cursor: pointer;
  color: var(--main-color-500);
  fill: var(--main-color-500);
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline var(--main-color-500);
  }
}
.loading {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

th:first-child,
td:first-child {
  padding: 1rem;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
}
</style>
