<template>
  <transition name="modal" appear>
    <div v-if="isOpen" class="modal__mask">
      <div v-click-outside="close" class="modal__container" :class="sizeClass">
        <h4 v-if="$slots.title" class="modal__title"><slot name="title"></slot></h4>
        <button class="modal__close--button" type="button" @click="close">
          <unicon name="times" fill="#8B8A87" height="18px" width="18px"></unicon>
        </button>
        <div class="modal__content"><slot name="content"></slot></div>
        <div class="modal__buttons" v-if="$slots.buttons"><slot name="buttons"></slot></div>
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
  name: 'Modal',

  data: () => ({
    isOpen: false,
  }),

  props: {
    size: {
      default: 'sm',
      type: String,
    },
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  methods: {
    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
      this.$emit('close');
    },
  },

  computed: {
    sizeClass() {
      switch (this.size) {
        case 'xlg':
          return 'modal--xlg';
        case 'slg':
          return 'modal--slg';
        case 'lg':
          return 'modal--lg';

        case 'md':
          return 'modal--md';

        case 'sm':
          return 'modal--sm';

        case 'free':
          return 'modal--free';

        default:
          return 'modal--sm';
      }
    },
  },
};
</script>

<style lang="scss">
.modal__mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  .modal__container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0.25em;
    overflow-y: hidden;
    position: relative;

    &.modal--sm {
      width: 480px;
    }
    &.modal--md {
      width: 600px;
      height: 80%;
    }
    &.modal--lg {
      width: 650px;
      height: 90%;
    }
    &.modal--slg {
      width: 85%;
      height: 70%;
      max-width: 870px;
      max-height: 520px;
    }
    &.modal--xlg {
      width: 85%;
      height: 90%;
      max-width: 970px;
    }
    &.modal--free {
    }
  }

  .modal-enter,
  .modal-leave-to {
    opacity: 0;
  }

  .modal-enter-active,
  .modal-leave-active {
    transition: opacity 300ms;
  }

  .modal-enter-to {
    opacity: 1;
  }

  .modal__close--button {
    z-index: 1;
    width: fit-content;
    position: absolute;
    right: 1.5rem;
    top: 1.3rem;
    border-radius: 100%;
    background-color: var(--gray-color-100);
    padding: 0.1rem;
    &:hover {
      background-color: var(--gray-color-300);
    }
  }

  .modal__title ~ .modal__content {
    flex-grow: 1;
    overflow: hidden;
  }

  .modal__content {
    flex-grow: 1;
    overflow: hidden;
  }

  .modal__buttons {
    align-items: center;
    // background-color: var(--gray-color-100);
    border-top: solid 1px var(--gray-color-300);
    padding: 1rem 2rem;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    height: 4rem;
  }

  .modal__title {
    margin: 0 1.5rem;
    padding: 1.3rem 0;
    padding-bottom: 1.1rem;
    border-bottom: solid 2px var(--gray-color-200);
  }
}
</style>
