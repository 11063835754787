var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view__container"},[_c('div',{staticClass:"content__top"},[_c('div',[_c('Breadcrumbs',{attrs:{"views":[{ label: 'Correos', to: '/gestion-de-documentos/correos' }],"currentView":{ label: 'Direcciones de correo' }}}),_c('h2',[_vm._v("Direcciones de correo")])],1),_c('div',{staticClass:"content__buttons"},[_c('Button',{attrs:{"type":"button","variant":"secondary","size":"small"},on:{"click":_vm.openExportModal}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"file-upload"}}),_vm._v(" Exportar ")],1)],1)]),_c('div',{staticClass:"content"},[_c('table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Email")]),_vm._l((_vm.events),function(event){return _c('th',{key:event},[_vm._v(" "+_vm._s(event.charAt(0).toUpperCase() + event.slice(1))+" ")])}),_c('th')],2),(_vm.addresses.length && !_vm.isLoading)?_vm._l((_vm.addresses),function(email,index){return _c('tr',{key:email.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(email.id))]),_vm._l((_vm.events),function(event){return _c('td',{key:event},[_vm._v(_vm._s(email[event] || 0))])}),_c('td',{staticClass:"redirect"},[_c('router-link',{attrs:{"to":{
                  name: 'Events',
                  params: { id: _vm.$route.params.id, emailId: email.id },
                },"type":"button"}},[_vm._v(" Ver más... ")])],1)],2)}):_vm._e()],2)]),(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('loading-spinner')],1):_vm._e()]),_c('export-modal',{ref:"modal__export",attrs:{"formats":{
      excel: {
        id: 'excel',
        name: 'Excel',
        options: [
          { id: 'mails', name: 'Direcciones de correo' } ],
      },
    }},on:{"export":_vm.downloadExcel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }